.wrapper {
  width: 100%;
  margin: 2rem 0 4rem 0;
}

.hero-img {
  border-radius: 16px;
  width: 80%;
  margin: auto;
  display: block;
}
.heading-text {
  overflow: hidden;
  margin: auto;
  width: fit-content;
  margin-bottom: 2rem;
}

.first {
  margin-right: 72px;
}

.heading-text span {
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 224px;
  font-family: "Maragsa";
}

@media (max-width: 1440px) {
  span {
    font-size: 128px;
  }
}

.body-text {
  font-size: 96px;
  font-family: "Darker Grotesque";
  text-align: center;
  margin-top: 0;
}

.body-text span {
  font-weight: 600;
}

.intro-section {
  position: relative;
  height: 500vh;
  background-color: var(--lightOliveGreen);
  padding: 4rem 0;
}
.intro-section-text {
  overflow: hidden;
  margin: auto;
  width: fit-content;
  font-weight: 400;
  font-size: 96px;
  color: var(--white);
  text-align: center;
}
.intro-section-card-text,
.bold {
  color: var(--darkBlue);
}

.intro-section-caurosel {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100vh;
}

.intro-section-card-wrapper {
  display: flex;
  gap: 4px;
}
.intro-section-card {
  height: 450px;
  width: 450px;
  overflow: hidden;
  position: relative;
}

.intro-section-card-img {
  position: absolute;
  inset: 0;
  z-index: 0;
  filter: opacity(0.4);
  border-radius: 16px;
}

.intro-section-card-text {
  font-size: 5rem;
  bottom: 0;
  right: 1rem;
  font-weight: 600;
  z-index: 10;
  position: absolute;
}

.intro-section-card:hover .intro-section-card-img {
  filter: opacity(1);
}

.intro-section-card:hover .intro-section-card-text {
  color: var(--white);
}
.intro-section-text-2 {
  font-size: 48px;
  text-align: center;
}

.facts-wrap {
  background-color: #eeeeee;
}
.facts-card-wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
}

.facts-card {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -25%;
  height: 500px;
  width: 1000px;
  border-radius: 25px;
  padding: 50px;
  transform-origin: top;
  box-shadow: 11px -10px 20px 0px rgb(59 58 58 / 21%);
}

.facts-card h2 {
  text-align: center;
  margin: 0px;
  font-size: 28px;
  font-family: "Maragsa";
}

.facts-card-content {
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 2rem;
  padding: 2rem 0;
}

.facts-card-content img {
  width: 40%;
  height: auto;
}

.facts-card-content p {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}

.support-wrap {
  position: relative;
  background-color: var(--lightNewsGrey);
}

.support-card-wrap {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 90%;
  margin: 2rem auto 0 auto;
  gap: 48px;
}
.support-card-wrap ul {
  padding: 0;
  margin: 0;
}

.support-actions {
  width: 60%;
  padding: 20vh 0;
}

.support-content {
  list-style: none;
  padding: 24px 0;
  opacity: 0.3;
  font-size: 24px;
}
.support-content .title {
  font-size: 48px;
  margin: 0;
}

.support-content.active {
  opacity: 1;
  background: #000;
  padding-left: 16px;
  color: white;
}
.support-content.active .title {
  font-weight: 600;
}

.support-img-wrapper {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
}

.support-img-wrapper img {
  width: 480px;
  height: 480px;
  border-radius: 16px;
  outline: none;
  filter: grayscale(1);
}

.benefits-wrap {
  position: relative;
  background-color: var(--lightNewsGrey);
  padding: 4rem 0;
}

.history-wrap {
  position: relative;
  padding: 4rem 0;
  background-color: #000;
  color: #fff;
}

.history-sections {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 3rem 0;
  padding: 0 120px;
}

.history-sections > * {
  width: 300px;
}

.history-img {
  width: 80vh;
  height: auto;
  display: block;
  margin: auto;
}
