.wrapper {
  position: relative;
  z-index: 0;
}
.mandala-bg {
  position: absolute;
  width: 100%;
  height: 50px;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 5px;
  background-image: url("../../images/uncompressed/mandala/mandala1.png");
}

.about-us {
  font-size: 28px;
  padding: 0 10rem;
}
.story {
  border: 1px solid;
  padding: 0 16px;
  border-radius: 8px;
  background: var(--lightNeonGreen);
}

.story .section-title {
  margin: 0;
}
.story .section-description {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
.story img {
  filter: invert(1);
  mix-blend-mode: difference;
  border: 1px solid #5959590f;
  border-radius: 8px;
}

.section-title {
  font-size: 48px;
}

.faqs-card-wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
}

.faqs-card {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -15%;
  height: 200px;
  width: 1000px;
  border-radius: 24px;
  padding: 50px;
  transform-origin: top;
  box-shadow: 11px -10px 20px 0px rgb(59 58 58 / 21%);
}

.faqs-card h2 {
  text-align: center;
  margin: 0px;
  font-size: 28px;
  font-family: "Maragsa";
}

.faqs-card-content {
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 2rem;
  padding: 2rem 0;
}

.faqs-card-content p {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}

.suggest-form-link {
  display: flex;
  padding: 1.1rem 2.5rem 1.1rem 3.5rem;
  border-radius: 99rem;
  background-color: #fff;
  transition: all 0.5s ease;
  justify-content: center;
  align-items: center;
  gap: 1em;
  border: none;
  font-size: 18px;
  margin-top: 1rem;
}

.suggest-form-link .suggest-form-icon {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.suggest-form-link .suggest-form-icon span {
  background: black;
  border-radius: 100%;
  padding: 0rem;

  width: 0rem;
  height: 0rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  transform: rotate(-180deg);

  transition: all 0.5s ease;
}

.suggest-form-link:hover .suggest-form-icon span {
  transform: rotate(0deg);

  border-radius: 100%;
  padding: 0.6rem;
  width: 0.9rem;
  height: 0.9rem;
}

.faqs-about {
  font-size: 28px;
  padding: 0 10rem 5rem 10rem;
  background: #000;
  color: white;
}
