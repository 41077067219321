.page-bg {
  background-color: var(--lightOrange);
}

nav {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: end;
}

header {
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-family: "Maragsa";
  position: relative;
}

.transition-slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: bottom;
}
.transition-slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0f0f0f;
  transform-origin: top;
}

.not-found {
  background: white;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.not-found h1 {
  font-weight: 400;
  font-size: 98px;
  font-family: "Maragsa";
}
.not-found img {
  width: 500px;
  height: 500px;
}

.beta-stage-logo {
  width: 20px;
  height: 20px;
  position: absolute;
  opacity: 0.8;
  top: 0;
}
