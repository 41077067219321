@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@400;600;800&display=swap");

:root {
  --lightOrange: #ffc6ac;
  --lightYellow: #fff6dc;
  --lightOliveGreen: #c4c1a4;
  --lightNeonGreen: #f9f7c9;
  --lightNewsGrey: #f3dfc1;
  --lightGreen: #c5e8a0;
  --white: #fff;
  --lightGrey: #9e9fa5;
  --darkBlue: #003953;
  --darkViolet: #000923;
  --lightViolet: #3b3e5b;
  --bluishGrey: #61605a;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Darker Grotesque", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--bluishGrey);
  font-weight: 500;
  font-size: 2rem;
}
a.active {
  color: var(--darkBlue);
  font-weight: 600;
  font-size: 2rem;
}

.nav-link {
  color: #18272f;
  position: relative;
  text-decoration: none;
}
.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: 4px;
  background-color: #18272f;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.nav-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.nav-link.active {
  color: var(--darkBlue);
  font-weight: 600;
  font-size: 2rem;
}

.coming-soon-banner {
  background: var(--lightGreen);
  width: 100%;
  height: 14rem;
  position: absolute;
  bottom: 0;
  z-index: -1;
  text-align: center;
  color: var(--darkBlue);
}
.coming-soon-banner span {
  font-size: 6rem;
  opacity: 0.5;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
